import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { PageSeo } from "../components/PageSeo"
import Layout from "../components/Layout"
import "./styles/offerings.scss"

const ProductsSlug = ({ data }) => {
  const { markdownRemark: markup } = data
  const { logo, name, banner, blurb, description, content, industriesOffering } =
    markup.frontmatter
  const usage = data.allMarkdownRemark.edges.filter(item => {
    return industriesOffering.includes(item.node.frontmatter.nameRef)
  })

  return (
    <Layout>
      <section className="ProductsSlug">
       
        <div className="container text-center py-5 animate__animated animate__fadeIn">
        <GatsbyImage
            image={logo.childImageSharp.gatsbyImageData}
            alt={name}
          />
          <div className="row mt-4">
          
            {/* <h2 className="section_heading">{name}</h2> */}
            <div className="col-12 col-lg-8 mx-auto">
              <ReactMarkdown children={description} />
            </div>
            <div className="col-12 col-lg-8 mx-auto animate__animated animate__fadeInUp">
              <ReactMarkdown children={content} />
            </div>
          </div>
        </div>

        <div className="banner animate__animated">
          <GatsbyImage
            image={banner.image.childImageSharp.gatsbyImageData}
            alt={name}
            className="bannerImage"
          />
          <div className="container text-white text-start d-none d-lg-block">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="text-white animate__animated animate__fadeIn">
                  {banner.heading}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <Features blurb={blurb} />
        <Usage usage={usage} />
      </section>
    </Layout>
  )
}
ProductsSlug.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

const Features = ({ blurb }) => {
  return (
    <div className="py-3 py-lg-5 mb-3">
      <div className="container">
        <h3 className="section_heading">Features</h3>
        <div className="row mt-3">
          <div className="col-12 col-lg-3 d-none d-lg-block">
            <div className="nav flex-column nav-pills tab-vertical-custom me-3">
              {blurb.length &&
                blurb.map((item, index) => (
                  <button
                    key={`features_nav_${index}`}
                    className={`nav-link rounded-0 btn-default d-block text-start ${
                      index === 0 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    data-bs-target={`#blurb-content_${index}`}
                  >
                    {item.title}
                  </button>
                ))}
            </div>
          </div>
          <div className="col-12 col-lg-9">
            {blurb.length &&
              blurb.map((item, index) => (
                <div
                  className="tab-content mb-3 mb-lg-0"
                  key={`features_tab_${index}`}
                >
                  <div
                    className={`tab-pane ${index === 0 ? "active" : ""}`}
                    id={`blurb-content_${index}`}
                    key={`blurb_${index}`}
                  >
                    <div className="row">
                      <div className="col-12 col-lg-8">
                        <h4 className="tab_heading">{item.title}</h4>
                        <p className="my-4">{item.content}</p>
                      </div>
                      <div className="col-12 col-lg-4" key={`blurb_${index}`}>
                        <GatsbyImage
                          image={item.image.childImageSharp.gatsbyImageData}
                          alt={item.title}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
Features.propTypes = {
  blurb: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
}

const Usage = ({ usage }) => {
  return (
    <div className="py-3 mb-5">
      <div className="container">
        <h3 className="section_heading pb-3">Usage Across Industries</h3>
        <ul className="nav nav-pills mb-3 tab-horizontal-custom d-none d-lg-flex">
          {usage.length &&
            usage.map(({ node }, index) => (
              <li className="nav-item" key={`usage_nav_${index}`}>
                <button
                  className={`nav-link rounded-0 border-0 ${
                    index === 0 ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  data-bs-target={`#usage-content_${index}`}
                >
                  <b> {node.frontmatter.name} </b>
                </button>
              </li>
            ))}
        </ul>
        <div>
          {usage.length &&
            usage.map(({ node }, index) => (
              <div
                className="tab-content mb-3 mb-lg-0"
                key={`usage_tab_${index}`}
              >
                <div
                  className={`tab-pane bg-white border rounded ${
                    index === 0 ? "active" : ""
                  } p-3`}
                  id={`usage-content_${index}`}
                >
                  <div className="row">
                    <div className="col-12 col-lg-8">
                      <ReactMarkdown
                        children={node.frontmatter.content}
                      ></ReactMarkdown>

                      <div className="mb-3 mb-lg-0">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            navigate(node.frontmatter.url)
                          }}
                          title={`Read More on ${node.frontmatter.name}`}
                        >
                          {" "}
                          Read More{" "}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 text-end">
                      <GatsbyImage
                        image={
                          node.frontmatter.image.childImageSharp.gatsbyImageData
                        }
                        alt={node.frontmatter.name}
                        className="rounded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
Usage.propTypes = {
  usage: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      heading: PropTypes.string,
      content: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
}

export const ProductQuery = graphql`
  query ProductQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seoContent {
          description
          keywords
          title
        }
        name
        description
        content
        industriesOffering
        image {
          childImageSharp {
            gatsbyImageData(width: 50, quality: 100, layout: FULL_WIDTH)
          }
        }
        logo {
          childImageSharp {
            gatsbyImageData(height: 50, quality: 100, layout: CONSTRAINED)
          }
        }
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        heading
        blurb {
          title
          content
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "industries-slug" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            description
            nameRef
            content
            link
            url
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
export default ProductsSlug

export const Head = ({ data }) => (
  <PageSeo
    title={
      data.markdownRemark.frontmatter.seoContent.title ||
      data.markdownRemark.frontmatter.name
    }
    description={
      data.markdownRemark.frontmatter.seoContent.description ||
      data.markdownRemark.frontmatter.name
    }
    keywords={
      data.markdownRemark.frontmatter.seoContent.keywords ||
      data.markdownRemark.frontmatter.name
    }
  />
)
